<template>
  <div class="admin-category">
    <div class="title">
      <span class="s">分类列表</span>
    </div>
    <div class="search-cell">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item label="分类名称：">
          <el-input
            v-model="formInline.name"
            size="mini"
            placeholder="分类名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="状态：">
          <el-select size="mini" v-model="formInline.status" placeholder="全部">
            <el-option label="全部" value=""></el-option>
            <el-option label="启用" value="1"></el-option>
            <el-option label="禁用" value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button size="mini" type="primary" @click="getList"
            >查询</el-button
          >
          <el-button size="mini" type="success" @click="addAccess"
            >新增</el-button
          >
          <el-button size="mini" plain @click="handleReset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tab">
      <el-table
        size="small "
        border
        v-loading="loading"
        :tree-props="{ children: 'list' }"
        row-key="category_id"
        :data="tableData"
        style="width: 100%; text-algin: center"
        :header-cell-style="{ background: '#F2F2F2', 'border-color': '#000' }"
        :row-style="{ 'border-color': '#000' }"
        :cell-style="{ 'border-color': '#000' }"
        :header-row-style="{ 'border-color': '#000' }"
      >
        <!-- <el-table-column
          show-overflow-tooltip
          align="center"
          prop="category_id"
          label="序号"
          width="140"
        >
        </el-table-column> -->
        <el-table-column
          show-overflow-tooltip
          align="left"
          prop="name"
          label="分类名称"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="icon"
          label="分类图标"
        >
          <template slot-scope="scope">
            <el-image
              v-if="scope.row.image"
              style="width: 60px; height: 60px"
              :src="scope.row.image"
              fit="fit"
            ></el-image>
            <div
              v-else
              style="
                width: 60px;
                height: 60px;
                margin:0 auto
                text-align: center;
                line-height: 60px;
              "
            >
              无
            </div>
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="status"
          label="状态"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.status == 1 ? "启用" : "禁用" }}</span>
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="sales_price"
          label="最高销售价格"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="weigh"
          label="排序"
        >
        </el-table-column>

        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="address"
          label="操作"
        >
          <template slot-scope="scope">
            <span class="btn" @click="handleEdit(scope.row)">编辑</span
            ><span class="btn" @click="handleDel(scope.row)">删除</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
import { categoryList, categoryDel } from "@/api/admin.js";
export default {
  data() {
    return {
      formInline: {
        name: "",
        status: "",
      },
      loading: true,
      tableData: [],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.loading = true;
      categoryList({
        ...this.formInline,
      }).then((res) => {
        this.loading = false;
        if (res.code == 1) {
          this.tableData = res.data;
        }
      });
    },
    handleEdit(val) {
      this.$router.options.routes.forEach((item) => {
        if (item.name == "admins") {
          item.children.forEach((val) => {
            if (val.name == "adminAddCategory") {
      
              val.meta.bread[val.meta.bread.length - 1].name = "编辑分类";
            }
          });
        }
      });
      sessionStorage.ADMIN_CATEGORY_INFO = JSON.stringify(val);
      this.$router.push("adminAddCategory");
    },
    addAccess() {
      this.$router.options.routes.forEach((item) => {
        if (item.name == "admins") {
          item.children.forEach((val) => {
            if (val.name == "adminAddCategory") {
     
              val.meta.bread[val.meta.bread.length - 1].name = "新增分类";
            }
          });
        }
      });
      sessionStorage.removeItem("ADMIN_CATEGORY_INFO");
      this.$router.push("adminAddCategory");
    },
    handleDel(val) {
      this.$confirm("确定删除该分类吗?", "删除提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        categoryDel({
          category_id: val.category_id,
        }).then((res) => {
          if (res.code == 1) {
            this.$message.success("删除成功");
          }
          this.getList();
        });
      });
    },
    handleReset() {
      this.formInline = {
        name: "",
        status: "",
      };
      this.getList();
    },
  },
};
</script>
<style lang="less">
.admin-category {
  background: #fff;
  padding: 20px;
  .title {
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;
    .s {
      font-size: 14px;
      color: #ee4d2d;
      border-bottom: 2px solid #ee4d2d;
      padding: 0 10px;
      padding-bottom: 10px;
    }
  }
  .search-cell {
    padding: 10px 0 0;
  }
  .tab {
    .btn {
      font-size: 12px;
      cursor: pointer;
      margin: 0 10px;
    }
    .btn:hover {
      border-bottom: 1px solid #000;
    }
  }
}
.el-form-item__label {
  font-size: 12px;
}
.el-table--border .el-table__cell {
  border-color: #000;
}
</style>